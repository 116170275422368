import React, { createContext, useState, useCallback, useMemo, useContext, } from 'react';
import { noop } from 'utils/functions';
import { removeNullElements } from 'utils/array';
export const TabsContext = createContext({
    tab: 0,
    updateActiveTab: noop,
});
export const TabsConsumer = TabsContext.Consumer;
export const TabsProvider = ({ children }) => {
    const [tab, setTab] = useState(0);
    const updateActiveTab = useCallback((tab) => setTab(tab), []);
    return (React.createElement(TabsContext.Provider, { value: {
            tab,
            updateActiveTab,
        } }, children));
};
export const TabsContainer = ({ children, }) => {
    const { tab } = useContext(TabsContext);
    const tabsChildren = useMemo(() => removeNullElements(children), [children]);
    const activeTab = useMemo(() => React.Children.toArray(tabsChildren)[tab], [tab, tabsChildren]);
    return (React.createElement("div", { className: "tabs__container" }, activeTab));
};
