import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { HomeCheats } from 'ui/organisms';
import { getList } from 'ducks/settings';
import { useLanguage } from 'hooks';
import { getHomeCheats } from 'ducks/home';
import { HomeTemplate } from 'ui/templates';
const Cheats = () => {
    const { tData } = useLanguage();
    const cheats = useSelector(getHomeCheats);
    const settings = useSelector(getList);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, tData(settings.homeTitle)),
            React.createElement("meta", { name: "description", content: tData(settings.homeDescription) })),
        React.createElement(HomeTemplate, null,
            React.createElement(HomeCheats, { cheats: cheats }))));
};
export default Cheats;
