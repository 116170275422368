import { createReducer } from 'typesafe-actions';
import { FETCH_FAILED, FETCH_DATA_SUCCESS, FETCH_STATUSES_SUCCESS, } from './constants';
const initialState = {
    data: null,
    statuses: [],
    error: null,
};
export default createReducer(initialState, {
    [FETCH_DATA_SUCCESS]: (state, { payload }) => (Object.assign(Object.assign({}, state), { data: payload.game, error: null })),
    [FETCH_STATUSES_SUCCESS]: (state, { payload }) => (Object.assign(Object.assign({}, state), { statuses: payload.statuses, error: null })),
    [FETCH_FAILED]: (state, { payload }) => (Object.assign(Object.assign({}, state), { error: payload.text || null })),
});
