import React from 'react';
import { Logo } from 'ui/atoms';
import { SocialLinks } from 'ui/molecules';
import './style.scss';
import { useLanguage } from 'hooks';
const year = new Date().getFullYear();
const Footer = ({ settings }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: "footer" },
        React.createElement("div", { className: "footer__top-line" }),
        React.createElement("div", { className: "footer__content" },
            React.createElement("div", { className: "footer__description" },
                React.createElement(Logo, null),
                React.createElement("p", null, i18n.t('FOOTER_DESCRIPTION'))),
            React.createElement(SocialLinks, { settings: settings })),
        React.createElement("div", { className: "footer__copyright" },
            "\u00A9 ",
            year,
            " GoldenCheats")));
};
export default Footer;
