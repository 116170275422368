import React from 'react';
import { Cheat } from 'ui/molecules';
import { useLanguage } from 'hooks';
import './style.scss';
const GameTemplate = ({ game }) => {
    const { i18n, tData } = useLanguage();
    return (React.createElement("div", { className: "game-template" },
        React.createElement("h1", { className: "game-template__title", dangerouslySetInnerHTML: {
                __html: tData(game.seo.title) || i18n.t('GAME_DEFAULT_SEO_TITLE').replace('#game#', game.title),
            } }),
        React.createElement("p", { className: "game-template__description" },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: tData(game === null || game === void 0 ? void 0 : game.content) } })),
        React.createElement("div", null,
            React.createElement("span", null,
                React.createElement("div", { className: "game-template__list" }, game.cheats.map(cheat => (React.createElement(Cheat, { key: cheat._id.toString(), gameSlug: game.slug, cheatSlug: cheat.slug, preview: cheat.preview, title: cheat.name, isPublished: cheat.isPublished, features: tData(cheat.features), status: cheat.status, minPrice: tData(cheat.minPrice) }))))),
            (!!game && !!tData(game === null || game === void 0 ? void 0 : game.seo.description)) && (React.createElement("div", { className: "game-template__description game-template__seo" },
                React.createElement("p", null,
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: tData(game.seo.description) } })))))));
};
export default GameTemplate;
