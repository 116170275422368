import React from 'react';
import { Image, Icon } from 'ui/atoms';
import { formatPrice, getStatusText } from 'utils/selector';
import { useLanguage } from 'hooks';
import Link from 'i18n/Link';
import './style.scss';
const Cheat = ({ gameSlug, gameName, cheatSlug, preview, title, minPrice, status, features, isPublished, }) => {
    const { i18n } = useLanguage();
    return (React.createElement(Link, { to: `/game/${gameSlug}/${cheatSlug}/`, className: "cheat" },
        React.createElement("div", { className: "cheat__preview" },
            React.createElement(Image, { webp: preview.webp, jpeg: preview.jpeg, alt: title }),
            React.createElement("div", { className: "cheat__info" },
                React.createElement("div", { className: "cheat__name" }, title),
                minPrice && (React.createElement("div", { className: "cheat__price" }, isPublished
                    ? formatPrice(minPrice, i18n)
                    : i18n.t('OUT_OF_STOCK')))),
            React.createElement("div", { className: "cheat__overlay" },
                React.createElement("div", { className: "cheat__btn" }, i18n.t('MORE')))),
        React.createElement("div", { className: "cheat__data" },
            gameName && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "cheat__subtitle" }, i18n.t('CHEAT_GAME')),
                React.createElement("div", { className: "cheat__item cheat__feature" },
                    React.createElement(Icon, { name: "check", width: "12", height: "12" }),
                    gameName))),
            status && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "cheat__subtitle" }, i18n.t('CHEAT_STATUS')),
                React.createElement("div", { className: "cheat__item cheat__status" },
                    React.createElement("span", { "data-status": status.toLocaleLowerCase() }),
                    getStatusText(status)))),
            features && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "cheat__subtitle" }, i18n.t('CHEAT_FEATURES_LIST')),
                features.map(feature => (React.createElement("div", { key: feature._id, className: "cheat__item cheat__feature" },
                    React.createElement(Icon, { name: "check", width: "12", height: "12" }),
                    feature.title))))))));
};
Cheat.defaultProps = {
    gameName: undefined,
};
export default Cheat;
