var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createI18n } from 'i18n';
import React, { createContext, useCallback, useState } from 'react';
export const LanguageContext = createContext({});
const listeners = [];
const loadedLanugages = {};
const getDictionary = (lang) => __awaiter(void 0, void 0, void 0, function* () {
    if (loadedLanugages[lang]) {
        return loadedLanugages[lang];
    }
    const res = yield fetch(`/dictionaries/${lang}.json`);
    const newDictionary = yield res.json();
    loadedLanugages[lang] = newDictionary;
    return newDictionary;
});
const LanguageProvider = ({ language: _language, dictionary: _dictionary, children, }) => {
    const [{ i18n, language }, setI18NState] = useState({
        i18n: createI18n(_language, _dictionary),
        language: _language,
    });
    if (typeof loadedLanugages[_language] === 'undefined') {
        loadedLanugages[_language] = _dictionary;
    }
    const changeLanguage = useCallback((newLanguage, history) => __awaiter(void 0, void 0, void 0, function* () {
        const newDictionary = yield getDictionary(newLanguage);
        setI18NState({
            language: newLanguage,
            i18n: createI18n(newLanguage, newDictionary),
        });
        const paths = history.location.pathname.split('/');
        paths.splice(0, 2);
        const path = paths.join('/');
        history.replace(`/${newLanguage}${path === ''
            ? '/'
            : `/${path}`}`);
        listeners.forEach(listener => listener());
    }), []);
    const addChangeLangListener = useCallback(listener => {
        listeners.push(listener);
    }, []);
    const removeChangeLangListener = useCallback(listener => {
        const index = listeners.indexOf(listener);
        index !== -1 && listeners.splice(index, 1);
    }, []);
    const tData = useCallback((data) => data === null || data === void 0 ? void 0 : data[language], [language]);
    return (React.createElement(LanguageContext.Provider, { value: {
            i18n,
            tData,
            language,
            changeLanguage,
            addChangeLangListener,
            removeChangeLangListener,
        } }, children));
};
export default LanguageProvider;
