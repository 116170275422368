import React from 'react';
import { preventFlashWallpaperState, usePreventFlashWallpaper, BackgroundWallpaper, Image, Icon, } from 'ui/atoms';
import { OrderForm, MediaTabs, DescriptionTabs, LastUpdatedSlider, } from 'ui/organisms';
import { useLanguage } from 'hooks';
import './style.scss';
const state = preventFlashWallpaperState();
const CheatTemplate = ({ cheat, lastUpdated, settings, }) => {
    const { i18n, tData } = useLanguage();
    usePreventFlashWallpaper(state);
    return (React.createElement("div", { className: "cheat-template" },
        React.createElement(BackgroundWallpaper, { color: cheat.accentColor, mounted: state.mounted }),
        React.createElement("div", { className: "cheat-template__row" },
            React.createElement("div", { className: "cheat-template__preview" },
                React.createElement(Image, { jpeg: cheat.preview.jpeg, webp: cheat.preview.webp, alt: cheat.name })),
            React.createElement(OrderForm, { cheat: cheat, settings: settings })),
        React.createElement("div", { className: "cheat-template__row" },
            React.createElement("div", { className: "cheat-template__features" },
                React.createElement("div", { className: "cheat__subtitle m-0" }, i18n.t('CHEAT_FEATURES_LIST')),
                tData(cheat.features).map((feature) => (React.createElement("div", { key: feature._id, className: "cheat__item cheat__feature" },
                    React.createElement(Icon, { name: "check", width: "12", height: "12" }),
                    feature.title)))),
            React.createElement(MediaTabs, { videos: tData(cheat.videos), screenshots: cheat.screenshots })),
        React.createElement("div", { className: "cheat-template__row" },
            React.createElement("div", null),
            React.createElement(DescriptionTabs, { description: tData(cheat.description) })),
        React.createElement(LastUpdatedSlider, { lastUpdated: lastUpdated })));
};
export default CheatTemplate;
