import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { HomeGames } from 'ui/organisms';
import { getList } from 'ducks/settings';
import { useLanguage } from 'hooks';
import { getHomeGames } from 'ducks/home';
import { HomeTemplate } from 'ui/templates';
const Home = () => {
    const { tData } = useLanguage();
    const games = useSelector(getHomeGames);
    const settings = useSelector(getList);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, tData(settings.homeTitle)),
            React.createElement("meta", { name: "description", content: tData(settings.homeDescription) })),
        React.createElement(HomeTemplate, null,
            React.createElement(HomeGames, { games: games, settings: settings }))));
};
export default Home;
