import React, { useState, useCallback, useRef, } from 'react';
import { useHistory } from 'react-router-dom';
import { scrollTop } from 'libs/router-config';
import { useLanguage, useListener } from 'hooks';
import { getWindowRef } from 'utils/selector';
import { SocialLinks } from 'ui/molecules';
import { Icon } from 'ui/atoms';
import Link from 'i18n/Link';
export const MobileHeader = ({ links, settings }) => {
    const history = useHistory();
    const { i18n, language, changeLanguage } = useLanguage();
    const refModal = useRef(null);
    const refButton = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [isSocial, setSocial] = useState(false);
    const handleToggle = useCallback(() => setOpen(prevOpen => !prevOpen), []);
    const handleHide = useCallback(() => {
        setOpen(false);
        scrollTop();
    }, []);
    const handleMissClick = useCallback(event => {
        var _a, _b;
        if (!((_a = refModal.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))
            && !((_b = refButton.current) === null || _b === void 0 ? void 0 : _b.contains(event.target))) {
            setOpen(false);
        }
    }, [refModal.current]);
    const handleToggleSocial = useCallback(() => setSocial(prevSocial => !prevSocial), []);
    const toggleLanguage = useCallback(() => changeLanguage(language === 'ru'
        ? 'en'
        : 'ru', history), [language]);
    useListener({
        target: getWindowRef(),
        event: 'click',
        listener: handleMissClick,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "header-navbar-mobile" },
            React.createElement("div", { className: "header-navbar-mobile__language-switcher", onClick: toggleLanguage },
                React.createElement(Icon, { name: language === 'en'
                        ? 'russia-flag'
                        : 'united-kingdom-flag', width: "24", height: "24", className: "header-social__icon" }),
                React.createElement("span", { className: "header-social__text" }, language === 'en'
                    ? 'RU'
                    : 'EN')),
            React.createElement("div", { ref: refButton, className: "header-navbar-mobile__humburger", onClick: handleToggle },
                React.createElement("span", null),
                React.createElement("span", null),
                React.createElement("span", null))),
        React.createElement("div", { ref: refModal, className: "header-navbar-mobile-modal", "data-open": isOpen },
            React.createElement("div", { className: "header-navbar-mobile-modal__cross", onClick: handleToggle }),
            links.map(link => {
                const LinkElement = link.local ? Link : 'a';
                return (React.createElement(LinkElement, Object.assign({ key: link.name }, (link.local
                    ? { to: link.link }
                    : {
                        href: link.link,
                        target: '_blank',
                        rel: 'noreferrer',
                    }), { onClick: handleHide, className: "header-navbar-mobile-modal__link" }), link.text));
            }),
            React.createElement("div", { className: "header-navbar-mobile-modal__social", "data-open": isSocial, onClick: handleToggleSocial },
                React.createElement("span", null, i18n.t('HEADER_SOCIAL')),
                React.createElement(Icon, { name: "arrow", width: "14", height: "14" })),
            React.createElement("div", { className: "header-navbar-mobile-modal__social-list", "data-open": isSocial },
                React.createElement(SocialLinks, { settings: settings })))));
};
