import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AdaptiveProvider, LanguageProvider } from 'providers';
import { initStore } from 'store';
import { useMount } from 'hooks';
import App from './App';
import 'styles/index.scss';
const store = initStore(window.__INITIAL_STATE__.store);
const Root = () => {
    useMount(() => {
        delete window.__INITIAL_STATE__.store;
    });
    return (React.createElement(AdaptiveProvider, null,
        React.createElement(LanguageProvider, { language: window.__INITIAL_LANGUAGE__, dictionary: window.__INITIAL_STATE__.dictionary },
            React.createElement(Provider, { store: store },
                React.createElement(BrowserRouter, null,
                    React.createElement(App, null))))));
};
export default Root;
