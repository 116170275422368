import React, { useMemo } from 'react';
import { Icon } from 'ui/atoms';
import { useLanguage } from 'hooks';
import './style.scss';
const SocialLinks = ({ settings }) => {
    const { i18n, tData, language } = useLanguage();
    const icons = useMemo(() => ([
        {
            link: tData(settings === null || settings === void 0 ? void 0 : settings.vkFooter),
            name: 'vk',
            text: i18n.t('SOCIAL_VK'),
        },
        {
            link: tData(settings === null || settings === void 0 ? void 0 : settings.discordFooter),
            name: 'discord',
            text: 'Discord',
        },
        {
            link: tData(settings === null || settings === void 0 ? void 0 : settings.telegramFooter),
            name: 'telegram',
            text: 'Telegram',
        },
        {
            link: tData(settings === null || settings === void 0 ? void 0 : settings.youtubeFooter),
            name: 'youtube',
            text: 'YouTube',
        },
    ]), [settings, language]);
    return (React.createElement("div", { className: "social" }, icons.map(icon => (React.createElement("a", { key: icon.name, href: icon.link, target: "_blank", rel: "noreferrer", "data-name": icon.name, className: "social-link" },
        React.createElement("div", { className: "social-link__icon" },
            React.createElement(Icon, { name: icon.name, height: "32px", width: "32px" })),
        React.createElement("div", { className: "social-link__text" }, icon.text))))));
};
export default SocialLinks;
