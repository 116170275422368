import { createReducer } from 'typesafe-actions';
import { FETCH_FAILED, FETCH_GAMES_SUCCESS, FETCH_CHEATS_SUCCESS, FETCH_UPDATED_SUCCESS, } from './constants';
const initialState = {
    allList: {
        games: [],
        cheats: [],
        lastUpdated: [],
    },
    count: null,
};
export default createReducer(initialState, {
    [FETCH_GAMES_SUCCESS]: (state, { payload }) => (Object.assign(Object.assign({}, state), { allList: Object.assign(Object.assign({}, state.allList), { games: payload.games }), count: payload.count })),
    [FETCH_UPDATED_SUCCESS]: (state, { payload }) => (Object.assign(Object.assign({}, state), { allList: Object.assign(Object.assign({}, state.allList), { lastUpdated: payload.games }) })),
    [FETCH_CHEATS_SUCCESS]: (state, { payload }) => (Object.assign(Object.assign({}, state), { allList: Object.assign(Object.assign({}, state.allList), { cheats: payload.cheats }), count: payload.count })),
    [FETCH_FAILED]: state => (Object.assign({}, state)),
});
