import React, { useMemo, useState, useCallback } from 'react';
import { Logo } from 'ui/atoms';
import { useMobile, useListener, useLanguage } from 'hooks';
import { getWindowRef } from 'utils/selector';
import { DesktopHeader, MobileHeader } from './components';
import './style.scss';
const Header = ({ settings }) => {
    const [isDarken, setDarken] = useState(false);
    const { language, i18n } = useLanguage();
    const { isDesktop } = useMobile();
    const links = useMemo(() => {
        var _a, _b;
        return ([
            {
                name: 'cube',
                text: i18n.t('HEADER_GAMES_CATALOG'),
                local: true,
                link: '/',
            },
            {
                name: 'shield',
                text: i18n.t('HEADER_STATUSES'),
                local: true,
                link: '/statuses/',
            },
            {
                name: 'chat',
                text: i18n.t('HEADER_TELEGRAM_CHAT'),
                local: false,
                link: (_a = settings.customerChat) === null || _a === void 0 ? void 0 : _a[language],
            },
            {
                name: 'reviews',
                text: i18n.t('HEADER_REVIEWS'),
                local: false,
                link: (_b = settings.reviewsLink) === null || _b === void 0 ? void 0 : _b[language],
            },
            {
                name: 'maintenance',
                text: i18n.t('HEADER_FAQ'),
                local: true,
                link: '/faq/',
            },
        ]);
    }, [settings, language]);
    const handleScroll = useCallback(() => setDarken(window.pageYOffset > 300), []);
    useListener({
        target: getWindowRef(),
        event: 'scroll',
        listener: handleScroll,
    });
    return (React.createElement("div", { "data-darken": isDarken, className: "header" },
        React.createElement(Logo, { className: "header-logo" }),
        isDesktop
            ? (React.createElement(DesktopHeader, { links: links, settings: settings })) : (React.createElement(MobileHeader, { links: links, settings: settings }))));
};
export default Header;
