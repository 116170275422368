import { combineReducers } from 'redux';
import { reducer as settings } from 'ducks/settings';
import { reducer as home } from 'ducks/home';
import { reducer as game } from 'ducks/game';
import { reducer as cheat } from 'ducks/cheat';
export default () => combineReducers({
    settings,
    home,
    game,
    cheat,
});
