var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiService } from 'services';
import { isEmpty } from 'utils/selector';
import { getStatuses } from './selectors';
import { fetchFailed, fetchDataSuccess, fetchStatusesSuccess, } from './actions';
export const fetchGame = (slug) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { game } = yield ApiService.get(`/game/home/${slug}`);
        yield dispatch(fetchDataSuccess(game));
    }
    catch (e) {
        if (e.status === 404) {
            yield dispatch(fetchFailed('Такая страница не найдена'));
            return 404;
        }
        yield dispatch(fetchFailed());
    }
});
export const fetchStatuses = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const statuses = getStatuses(getState());
    if (!isEmpty(statuses))
        return;
    try {
        const { statuses } = yield ApiService.get('/game/statuses');
        yield dispatch(fetchStatusesSuccess(statuses));
    }
    catch (e) {
        yield dispatch(fetchFailed());
    }
});
