import { createReducer } from 'typesafe-actions';
import { FETCH_FAILED, FETCH_SUCCESS, } from './constants';
const initialState = {
    data: null,
    error: null,
};
export default createReducer(initialState, {
    [FETCH_SUCCESS]: (state, { payload }) => (Object.assign(Object.assign({}, state), { data: payload.cheat, error: null })),
    [FETCH_FAILED]: (state, { payload }) => (Object.assign(Object.assign({}, state), { error: payload.text || null })),
});
