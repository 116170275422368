import React from 'react';
import { TabsProvider, TabsContainer } from 'providers';
import { Screenshots, Tabs, Videos } from './components';
import './style.scss';
const MediaTabs = ({ screenshots, videos }) => (React.createElement("div", { className: "media-tabs" },
    React.createElement(TabsProvider, null,
        React.createElement(Tabs, { isHasScreenshots: screenshots.length !== 0, isHasVideos: videos.length !== 0 }),
        React.createElement(TabsContainer, null,
            screenshots.length !== 0 && (React.createElement(Screenshots, { screenshots: screenshots })),
            videos.length !== 0 && (React.createElement(Videos, { videos: videos }))))));
export default MediaTabs;
