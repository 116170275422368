import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useListener, useLanguage } from 'hooks';
import { getWindowRef } from 'utils/selector';
import { SocialLinks } from 'ui/molecules';
import { Icon } from 'ui/atoms';
import Link from 'i18n/Link';
export const DesktopHeader = ({ settings, links }) => {
    const history = useHistory();
    const { i18n, changeLanguage, language } = useLanguage();
    const refModal = useRef(null);
    const refButton = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const handleToggle = useCallback(() => setOpen(prevOpen => !prevOpen), []);
    const handleMissClick = useCallback(event => {
        var _a, _b;
        if (!((_a = refModal.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))
            && !((_b = refButton.current) === null || _b === void 0 ? void 0 : _b.contains(event.target))) {
            setOpen(false);
        }
    }, [refModal.current]);
    const toggleLanguage = useCallback(() => changeLanguage(language === 'ru'
        ? 'en'
        : 'ru', history), [language]);
    useListener({
        target: getWindowRef(),
        event: 'click',
        listener: handleMissClick,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "header-navbar" }, links.map(link => {
            const LinkElement = link.local ? Link : 'a';
            return (React.createElement(LinkElement, Object.assign({ key: link.name }, (link.local
                ? { to: link.link }
                : {
                    href: link.link,
                    target: '_blank',
                    rel: 'noreferrer',
                }), { className: "header-navbar__link" }),
                React.createElement(Icon, { name: link.name, width: "24", height: "24", className: "header-navbar__icon" }),
                React.createElement("div", { className: "header-navbar__text" },
                    link.text,
                    React.createElement("span", null))));
        })),
        React.createElement("div", { className: "header-social" },
            React.createElement("div", { className: "header-social__btn", onClick: toggleLanguage },
                React.createElement(Icon, { name: language === 'en'
                        ? 'russia-flag'
                        : 'united-kingdom-flag', width: "24", height: "24", className: "header-social__icon" }),
                React.createElement("span", { className: "header-social__text" }, language === 'en'
                    ? 'РУССКИЙ'
                    : 'ENG')),
            React.createElement("div", { className: "header-social__separator" }),
            React.createElement("div", { ref: refButton, className: "header-social__btn", "data-active": isOpen, onClick: handleToggle },
                React.createElement(Icon, { name: "network", width: "24", height: "24", className: "header-social__icon" }),
                React.createElement("span", { className: "header-social__text" }, i18n.t('HEADER_SOCIAL')))),
        React.createElement("div", { ref: refModal, className: "header-social-modal", "data-open": isOpen },
            React.createElement(SocialLinks, { settings: settings }))));
};
