import React from 'react';
import { StatusItem } from 'ui/molecules';
import { useLanguage } from 'hooks';
const renderHead = (language, lastUpdated) => {
    switch (language) {
        case 'en':
            return (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "game-template__title" }, "CHEAT STATUSES"),
                React.createElement("p", { className: "game-template__description" },
                    React.createElement("div", null,
                        React.createElement("p", null,
                            "The statuses of the work of private cheats posted on our website. The information is current at the time: ",
                            React.createElement("strong", null, lastUpdated)),
                        React.createElement("p", null, "For the most part, our site is a reseller of popular private cheats."),
                        React.createElement("p", null, "We follow all changes, improvements and software updates. This page was created to inform users about the status of the cheats."),
                        React.createElement("p", null,
                            "Please note that the ",
                            React.createElement("strong", null, "Undetected"),
                            " status does not guarantee 100% blocking protection. "),
                        React.createElement("p", null, "Any cheat in any game can be banned."),
                        React.createElement("p", null, "I update the date above manually, so if you see today's date, the information is current."),
                        React.createElement("p", null, "Play as carefully as possible, remember that you can be banned for reporting and for frank play."))),
                React.createElement("h3", { className: "game-template__title" }, "MEANING OF STATUSES"),
                React.createElement("span", { className: "game-template__description" },
                    React.createElement("p", null,
                        React.createElement("strong", null, "Undetected / UD"),
                        " - minimum chance of being banned",
                        '\n',
                        React.createElement("strong", null, "Use at own risk"),
                        " - use at your own risk, big chance of being banned",
                        '\n',
                        React.createElement("strong", null, "Detected"),
                        " - the cheat is detected by the game system",
                        '\n',
                        React.createElement("strong", null, "On Update"),
                        " - on update",
                        '\n',
                        React.createElement("strong", null, "Outdated"),
                        " - outdated cheat"))));
        case 'ru':
        default:
            return (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "game-template__title" }, "\u0421\u0422\u0410\u0422\u0423\u0421\u042B \u0427\u0418\u0422\u041E\u0412"),
                React.createElement("p", { className: "game-template__description" },
                    React.createElement("div", null,
                        React.createElement("p", null,
                            "\u0421\u0442\u0430\u0442\u0443\u0441\u044B \u0440\u0430\u0431\u043E\u0442\u044B \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u044B\u0445 \u0447\u0438\u0442\u043E\u0432, \u0432\u044B\u043B\u043E\u0436\u0435\u043D\u043D\u044B\u0445 \u043D\u0430 \u043D\u0430\u0448\u0435\u043C \u0441\u0430\u0439\u0442\u0435. \u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u0430\u043A\u0442\u0443\u0430\u043B\u044C\u043D\u0430 \u043D\u0430 \u043C\u043E\u043C\u0435\u043D\u0442: ",
                            React.createElement("strong", null, lastUpdated)),
                        React.createElement("p", null, "\u0412 \u0431\u043E\u043B\u044C\u0448\u0438\u043D\u0441\u0442\u0432\u0435 \u0441\u0432\u043E\u0451\u043C, \u043D\u0430\u0448 \u0441\u0430\u0439\u0442 \u044F\u0432\u043B\u044F\u0435\u0442\u0441\u044F \u0440\u0435\u0441\u0435\u043B\u043B\u0435\u0440\u043E\u043C \u043F\u043E\u043F\u0443\u043B\u044F\u0440\u043D\u044B\u0445 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u044B\u0445 \u0447\u0438\u0442\u043E\u0432."),
                        React.createElement("p", null, "\u041C\u044B \u0441\u043B\u0435\u0434\u0438\u043C \u0437\u0430 \u0432\u0441\u0435\u043C\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F\u043C\u0438, \u0434\u043E\u0440\u0430\u0431\u043E\u0442\u043A\u0430\u043C\u0438 \u0438 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F\u043C\u0438 \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C. \u0414\u0430\u043D\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u0441\u043E\u0437\u0434\u0430\u043D\u0430 \u0441 \u0446\u0435\u043B\u044C\u044E \u0438\u043D\u0444\u043E\u0440\u043C\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u043E \u0441\u043E\u0441\u0442\u043E\u044F\u043D\u0438\u0438 \u0440\u0430\u0431\u043E\u0442\u044B \u0447\u0438\u0442\u043E\u0432."),
                        React.createElement("p", null,
                            "\u0418\u043C\u0435\u0439\u0442\u0435 \u0432\u0432\u0438\u0434\u0443, \u0447\u0442\u043E \u0441\u0442\u0430\u0442\u0443\u0441 ",
                            React.createElement("strong", null, "Undetected"),
                            " \u043D\u0435 \u0433\u0430\u0440\u0430\u043D\u0442\u0438\u0440\u0443\u0435\u0442 100% \u0437\u0430\u0449\u0438\u0442\u0443 \u043E\u0442 \u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u043E\u043A."),
                        React.createElement("p", null, "\u0421 \u043B\u044E\u0431\u044B\u043C \u0447\u0438\u0442\u043E\u043C \u0432 \u043B\u044E\u0431\u043E\u0439 \u0438\u0433\u0440\u0435 \u0432\u043E\u0437\u043C\u043E\u0436\u0435\u043D \u0431\u0430\u043D."),
                        React.createElement("p", null, "\u0414\u0430\u0442\u0443 \u0432\u044B\u0448\u0435 \u044F \u043E\u0431\u043D\u043E\u0432\u043B\u044F\u044E \u0432\u0440\u0443\u0447\u043D\u0443\u044E, \u043F\u043E\u044D\u0442\u043E\u043C\u0443 \u0435\u0441\u043B\u0438 \u0432\u044B \u0432\u0438\u0434\u0438\u0442\u0435 \u0441\u0435\u0433\u043E\u0434\u043D\u044F\u0448\u043D\u044E\u044E \u0434\u0430\u0442\u0443 - \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u0430\u043A\u0442\u0443\u0430\u043B\u044C\u043D\u0430."),
                        React.createElement("p", null, "\u0418\u0433\u0440\u0430\u0439\u0442\u0435 \u043C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u043E \u0430\u043A\u043A\u0443\u0440\u0430\u0442\u043D\u043E, \u043F\u043E\u043C\u043D\u0438\u0442\u0435, \u0447\u0442\u043E \u0437\u0430\u0431\u0430\u043D\u0438\u0442\u044C \u043C\u043E\u0433\u0443\u0442 \u0437\u0430 \u0440\u0435\u043F\u043E\u0440\u0442\u044B \u0438 \u0437\u0430 \u043E\u0442\u043A\u0440\u043E\u0432\u0435\u043D\u043D\u0443\u044E \u0438\u0433\u0440\u0443."))),
                React.createElement("h3", { className: "game-template__title" }, "\u0417\u041D\u0410\u0427\u0415\u041D\u0418\u0415 \u0421\u0422\u0410\u0422\u0423\u0421\u041E\u0412"),
                React.createElement("span", { className: "game-template__description" },
                    React.createElement("p", null,
                        React.createElement("strong", null, "Undetected / UD"),
                        " - \u043C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u044B\u0439 \u0448\u0430\u043D\u0441 \u0431\u0430\u043D\u0430",
                        '\n',
                        React.createElement("strong", null, "Use at own risk"),
                        " - \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0439\u0442\u0435 \u043D\u0430 \u0441\u0432\u043E\u0439 \u0441\u0442\u0440\u0430\u0445 \u0438 \u0440\u0438\u0441\u043A, \u0431\u043E\u043B\u044C\u0448\u043E\u0439 \u0448\u0430\u043D\u0441 \u0431\u0430\u043D\u0430",
                        '\n',
                        React.createElement("strong", null, "Detected"),
                        " - \u0447\u0438\u0442 \u043E\u0431\u043D\u0430\u0440\u0443\u0436\u0438\u0432\u0430\u0435\u0442 \u0441\u0438\u0441\u0442\u0435\u043C\u0430 \u0438\u0433\u0440\u044B",
                        '\n',
                        React.createElement("strong", null, "On Update"),
                        " - \u043D\u0430 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u0438",
                        '\n',
                        React.createElement("strong", null, "Outdated"),
                        " - \u0443\u0441\u0442\u0430\u0440\u0435\u0432\u0448\u0438\u0439 \u0447\u0438\u0442"))));
    }
};
const StatusesTemplate = ({ statuses, lastUpdated, }) => {
    const { language } = useLanguage();
    return (React.createElement("div", { className: "game-template" },
        renderHead(language, lastUpdated),
        React.createElement("div", null,
            React.createElement("div", { className: "game-template__list" }, statuses.map(game => game.cheats.length !== 0 && (React.createElement(StatusItem, { key: game._id.toString(), gameSlug: game.slug, cheats: game.cheats, preview: game.preview, title: game.title })))))));
};
StatusesTemplate.defaultProps = {
    lastUpdated: '',
};
export default StatusesTemplate;
