import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useListener, useMount, useSSREffect } from 'hooks';
import { removeLangPrefix } from 'libs/router-config';
import { getWindowRef } from 'utils/selector';
import './style.scss';
export const preventFlashWallpaperState = () => ({
    mounted: false,
});
export const usePreventFlashWallpaper = (state) => {
    const location = useLocation();
    useMount(() => {
        state.mounted = true;
    });
    useSSREffect(() => () => {
        if (!['/cheats/', '/'].includes(removeLangPrefix(location.pathname))) {
            state.mounted = false;
        }
    });
};
const BackgroundWallpaper = ({ color, mounted, }) => {
    const [opacity, setOpacity] = useState(1);
    const handleScroll = useCallback(() => {
        const height = window.innerHeight;
        if (window.scrollY < height - 200) {
            const offsetPercent = (height - window.scrollY) / (height / 100);
            setOpacity(+(offsetPercent / 100).toFixed(2));
        }
    }, []);
    useSSREffect(handleScroll);
    useListener({
        target: getWindowRef(),
        event: 'scroll',
        listener: handleScroll,
        options: {
            passive: true,
            capture: true,
        },
    });
    return (React.createElement("div", { className: "background-wallpaper", "data-image": !color, "data-mounted": mounted, style: Object.assign({ opacity }, (color
            ? { '--bg': color }
            : {})) }));
};
export default BackgroundWallpaper;
