import { Status } from './enum';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProduction = process.env.NODE_ENV === 'production';
export const isBrowser = typeof window !== 'undefined';
export const getWindow = () => isBrowser ? window : null;
export const getWindowRef = () => ({
    current: getWindow(),
});
export const isEmpty = (obj) => Object.keys(obj).length === 0;
export const isNull = (value) => value === null;
export const staticFile = (url) => `${process.env.RAZZLE_APP_API_URL}/static${url}`;
export const formatCurrency = (currency, i18n) => {
    switch (currency.toUpperCase()) {
        case 'RUB':
            return i18n.language === 'ru' ? 'руб.' : 'rub.';
        case 'USD':
            return 'usd';
        case 'CNY':
            return 'cny';
    }
};
export const formatPrice = (input, i18n) => {
    if (isNull(input.price)) {
        return i18n.t('OUT_OF_STOCK');
    }
    return i18n.t('PRICE_FROM')
        .replace('#price#', `${input.price} ${formatCurrency(input.currency, i18n)}`);
};
export const getStatusText = (status) => {
    switch (status) {
        case Status.UNDETECTED:
            return 'Undetected / UD';
        case Status.USE_AT_OWN_RISK:
            return 'Use at own risk';
        case Status.DETECTED:
            return 'Detected';
        case Status.UPDATING:
            return 'On Update';
        case Status.OUTDATED:
            return 'Outdated';
    }
};
