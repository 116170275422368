import React from 'react';
import { useLanguage } from 'hooks';
const FaqTemplate = ({ settings }) => {
    const { i18n, tData } = useLanguage();
    return (React.createElement("div", { className: "game-template" },
        React.createElement("h3", { className: "game-template__title" }, i18n.t('FAQ_TITLE')),
        React.createElement("p", { className: "game-template__description" },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: tData(settings.faq) || '' } }))));
};
export default FaqTemplate;
