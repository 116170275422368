var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiService } from 'services';
import { isEmpty } from 'utils/selector';
import { getLastUpdatedGames, getHomeGames, getHomeCheats, } from './selectors';
import { fetchFailed, fetchGamesSuccess, fetchCheatsSuccess, fetchUpdatedSuccess, } from './actions';
export const fetchHomeGames = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const gamesList = getHomeGames(getState());
    if (!isEmpty(gamesList))
        return;
    try {
        const { count, games } = yield ApiService.get('/game/home');
        yield dispatch(fetchGamesSuccess({
            count,
            games,
        }));
    }
    catch (e) {
        console.log(e, 'fetchHomeGames');
        yield dispatch(fetchFailed());
    }
});
export const fetchLastUpdatedGames = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const lastUpdated = getLastUpdatedGames(getState());
    if (!isEmpty(lastUpdated))
        return;
    try {
        const { games } = yield ApiService.get('/game/last-updated');
        yield dispatch(fetchUpdatedSuccess(games));
    }
    catch (e) {
        console.log(e, 'fetchLastUpdatedGames');
        yield dispatch(fetchFailed());
    }
});
export const fetchHomeCheats = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const cheatsList = getHomeCheats(getState());
    if (!isEmpty(cheatsList))
        return;
    try {
        const { count, cheats } = yield ApiService.get('/cheat/home');
        yield dispatch(fetchCheatsSuccess({
            count,
            cheats,
        }));
    }
    catch (e) {
        console.log(e, 'fetchHomeCheats');
        yield dispatch(fetchFailed());
    }
});
