var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class ApiService {
    static request(method, url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const fullOptions = Object.assign({
                method,
            }, {
                headers: this.getHeaders,
            }, options);
            if (process.env.NODE_ENV === 'test') {
                return fetch(`${(/^http/i).test(url) ? '' : this.baseUri}${url}`, fullOptions).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    if (!response.ok) {
                        throw response;
                    }
                    return response;
                });
            }
            return yield this.makeRequest(url, fullOptions);
        });
    }
    static get(url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.request('GET', url, options);
        });
    }
    static post(url, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const fullOptions = this.formatBody(body, options);
            return yield this.request('POST', url, fullOptions);
        });
    }
    static put(url, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const fullOptions = this.formatBody(body, options);
            return yield this.request('PUT', url, fullOptions);
        });
    }
    static patch(url, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const fullOptions = this.formatBody(body, options);
            return yield this.request('PATCH', url, fullOptions);
        });
    }
    static delete(url, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const fullOptions = this.formatBody(body, options);
            return yield this.request('DELETE', url, fullOptions);
        });
    }
    static formatBody(body = {}, options) {
        if (options && options.isBinary) {
            return Object.assign({}, {
                body,
            }, options);
        }
        return this.makeBody(body, options);
    }
    static makeRequest(url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield fetch(`${(/^http/i).test(url) ? '' : this.baseUri}${url}`, options)
                .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                if (!response.ok) {
                    throw response;
                }
                return response;
            });
        });
    }
    static makeBody(body, options) {
        return Object.assign({}, {
            body: JSON.stringify(body),
        }, options);
    }
}
ApiService.baseUri = process.env.RAZZLE_APP_API_URL;
ApiService.getHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
