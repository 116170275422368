import { PrimaryTemplate } from 'ui/templates';
import Home from 'pages/Home';
import homeLoadData from 'pages/Home/loadData';
import Cheats from 'pages/Cheats';
import cheatsLoadData from 'pages/Cheats/loadData';
import Game from 'pages/Game';
import gameLoadData from 'pages/Game/loadData';
import Statuses from 'pages/Statuses';
import statusesLoadData from 'pages/Statuses/loadData';
import Faq from 'pages/Faq';
import faqLoadData from 'pages/Faq/loadData';
import Cheat from 'pages/Cheat';
import cheatLoadData from 'pages/Cheat/loadData';
import Error from 'pages/Error';
import errorLoadData from 'pages/Error/loadData';
export const routes = [
    {
        path: '/:lang',
        component: PrimaryTemplate,
        routes: [
            {
                path: '/:lang/cheats',
                loadData: cheatsLoadData,
                component: Cheats,
                exact: true,
            },
            {
                path: '/:lang',
                loadData: homeLoadData,
                component: Home,
                exact: true,
            },
            {
                path: '/:lang/statuses',
                loadData: statusesLoadData,
                component: Statuses,
            },
            {
                path: '/:lang/faq',
                loadData: faqLoadData,
                component: Faq,
            },
            {
                path: '/:lang/game/:game/:cheat',
                loadData: cheatLoadData,
                component: Cheat,
                exact: true,
            },
            {
                path: '/:lang/game/:slug',
                loadData: gameLoadData,
                component: Game,
            },
            {
                loadData: errorLoadData,
                component: Error,
            },
        ],
    },
];
