import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { CheatTemplate, NotFound } from 'ui/templates';
import { getData, getFailed } from 'ducks/cheat';
import { getLastUpdatedGames } from 'ducks/home';
import { getAllList } from 'ducks/settings';
import { useLanguage } from 'hooks';
const Cheat = () => {
    const { tData } = useLanguage();
    const cheat = useSelector(getData);
    const lastUpdated = useSelector(getLastUpdatedGames);
    const settings = useSelector(getAllList);
    const error = useSelector(getFailed);
    if (error)
        return React.createElement(NotFound, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, tData(cheat === null || cheat === void 0 ? void 0 : cheat.page.title)),
            React.createElement("meta", { name: "description", content: tData(cheat === null || cheat === void 0 ? void 0 : cheat.page.description) })),
        React.createElement(CheatTemplate, { cheat: cheat, lastUpdated: lastUpdated, settings: settings })));
};
export default Cheat;
