var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Link from 'i18n/Link';
import './style.scss';
const defaultProps = {
    style: 'primary',
    tag: 'button',
    active: false,
};
const Button = (_a) => {
    var { tag, to, type, style, active, children } = _a, rest = __rest(_a, ["tag", "to", "type", "style", "active", "children"]);
    const Tag = tag === 'link'
        ? Link
        : tag || defaultProps.tag;
    return (React.createElement(Tag, Object.assign({ className: "button", "data-active": active, "data-style": style, type: type, to: to }, rest), children));
};
Button.defaultProps = defaultProps;
export default Button;
