var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiService } from 'services';
import { fetchFailed, fetchSuccess, } from './actions';
export const fetchCheat = (cheatSlug, gameSlug) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { cheat } = yield ApiService.get(`/cheat/${gameSlug}/${cheatSlug}`);
        yield dispatch(fetchSuccess(cheat));
    }
    catch (e) {
        if (e.status === 404) {
            yield dispatch(fetchFailed('Такая страница не найдена'));
            return 404;
        }
        yield dispatch(fetchFailed());
    }
});
