import React from 'react';
import { getStatusText } from 'utils/selector';
import { Image } from 'ui/atoms';
import Link from 'i18n/Link';
import './style.scss';
import { useLanguage } from 'hooks';
const StatusItem = ({ gameSlug, preview, title, cheats, }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: "cheat status-item" },
        React.createElement(Link, { to: `/game/${gameSlug}/`, className: "cheat__preview status-item__preview" },
            React.createElement(Image, { webp: preview.webp, jpeg: preview.jpeg, alt: title }),
            React.createElement("div", { className: "cheat__info" },
                React.createElement("div", { className: "cheat__name" }, title)),
            React.createElement("div", { className: "cheat__overlay" },
                React.createElement("div", { className: "cheat__btn" }, i18n.t('MORE')))),
        React.createElement("div", { className: "cheat__data" }, cheats.map(cheat => (React.createElement(Link, { key: cheat._id, to: `/game/${gameSlug}/${cheat.slug}/`, className: "cheat__item cheat__status" },
            React.createElement("span", { "data-status": cheat.status.toLowerCase() }),
            React.createElement("div", { className: "cheat__status-container header-navbar__text" },
                React.createElement("div", { className: "cheat__status-name" },
                    cheat.name,
                    " -"),
                getStatusText(cheat.status),
                React.createElement("span", null))))))));
};
StatusItem.defaultProps = {};
export default StatusItem;
