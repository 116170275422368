import React, { useMemo, useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Image, Icon } from 'ui/atoms';
const slidesToShow = 1;
export const Screenshots = ({ screenshots: items }) => {
    const sliderRef = useRef(null);
    const [isNextDisabled, setNextDisabled] = useState(items.length <= slidesToShow);
    const [isPrevDisabled, setPrevDisabled] = useState(true);
    const settings = useMemo(() => ({
        slidesToShow,
        arrows: false,
        infinite: false,
    }), []);
    const beforeChange = useCallback((_, next) => {
        setNextDisabled(next === items.length - slidesToShow);
        setPrevDisabled(next === 0);
    }, [sliderRef.current, items]);
    const handleNext = useCallback(() => { var _a; return (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickNext(); }, [sliderRef.current]);
    const handlePrev = useCallback(() => { var _a; return (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickPrev(); }, [sliderRef.current]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "last-updated-slider__prev", "data-disabled": isPrevDisabled, onClick: handlePrev },
            React.createElement(Icon, { name: "arrow", width: "14", height: "14" })),
        React.createElement("div", { className: "last-updated-slider__next", "data-disabled": isNextDisabled, onClick: handleNext },
            React.createElement(Icon, { name: "arrow", width: "14", height: "14" })),
        React.createElement("div", { className: "media-tabs__screenshots" },
            React.createElement(Slider, Object.assign({ ref: sliderRef, beforeChange: beforeChange }, settings), items.map(({ _id, screenPreview }) => (React.createElement("div", { key: _id, className: "media-tabs__screenshots-image" },
                React.createElement(Image, { jpeg: screenPreview.jpeg, webp: screenPreview.webp, alt: _id }))))))));
};
