import React from 'react';
import { TabsContainer, TabsConsumer, TabsProvider } from 'providers';
import { useLanguage } from 'hooks';
import { Button } from 'ui/atoms';
import './style.scss';
const DescriptionTabs = ({ description }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: "description-tabs" },
        React.createElement(TabsProvider, null,
            React.createElement(TabsConsumer, null, ({ updateActiveTab, tab }) => (React.createElement("div", { className: "description-tabs__tabs" },
                React.createElement(Button, { onClick: () => updateActiveTab(0), style: "tab", active: tab === 0 }, i18n.t('CHEAT_TAB_DESCRIPTION'))))),
            React.createElement(TabsContainer, null,
                React.createElement("p", { className: "description-tabs__content game-template__description" },
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: description } }))))));
};
export default DescriptionTabs;
