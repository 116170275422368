import React from 'react';
import { prefix } from 'inline-style-prefixer';
import './style.scss';
const Layout = ({ width, height, margin, padding, justify, align, flow, children, }) => (React.createElement("div", { className: "layout", style: prefix({
        width,
        height,
        margin,
        padding,
        justifyContent: justify,
        alignItems: align,
        flexDirection: flow,
    }) }, children));
Layout.defaultProps = {
    width: '100%',
    height: undefined,
    margin: undefined,
    padding: undefined,
    justify: undefined,
    align: undefined,
    flow: 'row',
};
export default Layout;
