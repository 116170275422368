import React from 'react';
import { useLanguage } from 'hooks';
import { Button } from 'ui/atoms';
import './style.scss';
const ErrorTemplate = () => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: "error-template" },
        React.createElement("div", { className: "game-template__title" }, "#404"),
        React.createElement("div", { className: "error-template__content" }, i18n.t('404_DESCRIPTION')),
        React.createElement("div", { className: "error-template__buttons-group" },
            React.createElement(Button, { tag: "link", to: "/" }, i18n.t('404_TO_HOME')))));
};
export default ErrorTemplate;
