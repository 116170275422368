import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { RadioField } from 'ui/molecules';
import { Button } from 'ui/atoms';
import { useLanguage } from 'hooks';
import { getStatusText, formatPrice } from 'utils/selector';
import './style.scss';
const OrderForm = ({ cheat, settings }) => {
    var _a;
    const { i18n, tData, language } = useLanguage();
    const prices = useMemo(() => tData(cheat.prices), [cheat, language]);
    const handleForm = useCallback(({ period }) => {
        const price = prices.find(({ _id }) => _id === period);
        if (price) {
            window.location.href = price.link;
        }
    }, [prices, settings]);
    return (React.createElement(Form, { initialValues: {
            period: (_a = prices === null || prices === void 0 ? void 0 : prices[0]) === null || _a === void 0 ? void 0 : _a._id,
        }, onSubmit: handleForm, render: ({ values, handleSubmit }) => {
            const price = prices.find(p => p._id === values.period);
            return (React.createElement("form", { className: "cheat-template__info", onSubmit: handleSubmit },
                React.createElement("h1", { className: "game-template__title ws-pl", dangerouslySetInnerHTML: {
                        __html: tData(cheat.seo.title) || i18n.t('CHEAT_DEFAULT_PAGE_TITLE')
                            .replace('#cheat#', cheat.name)
                            .replace('#game#', cheat.game.title),
                    } }),
                React.createElement("div", { className: "cheat__subtitle" }, i18n.t('CHEAT_STATUS')),
                React.createElement("div", { className: "cheat__item cheat__status" },
                    React.createElement("span", { "data-status": cheat.status.toLocaleLowerCase() }),
                    getStatusText(cheat.status)),
                React.createElement("div", { className: "cheat__subtitle" }, i18n.t('CHEAT_SELECT_PRICE')),
                React.createElement("div", { className: "cheat-template__info-periods-container" },
                    React.createElement("div", { className: "cheat-template__info-periods" }, prices.map(price => (React.createElement(RadioField, { key: price._id, name: "period", value: price._id, text: price.period, price: price }))))),
                React.createElement("div", { className: "cheat-template__info-bottom" },
                    React.createElement(Button, { type: "submit", onClick: handleSubmit, disabled: !cheat.isPublished || !values.period }, i18n.t(cheat.isPublished && !!values.period
                        ? 'CHEAT_ORDER_BUY'
                        : 'CHEAT_ORDER_UPDATING')),
                    React.createElement("div", { className: "cheat-template__info-price" }, price && formatPrice(price, i18n)))));
        } }));
};
export default OrderForm;
