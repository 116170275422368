import React from 'react';
import { StatusesTemplate } from 'ui/templates';
import { useSelector } from 'react-redux';
import { getStatuses } from 'ducks/game';
import { getAllList } from 'ducks/settings';
import { useLanguage } from 'hooks';
const Statuses = () => {
    const { tData } = useLanguage();
    const statuses = useSelector(getStatuses);
    const settings = useSelector(getAllList);
    return (React.createElement(StatusesTemplate, { statuses: statuses, lastUpdated: tData(settings.lastStatusUpdate) }));
};
export default Statuses;
