import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { renderRoutes, } from 'libs/router-config';
import { Switch } from 'react-router-dom';
import { Header, Footer } from 'ui/organisms';
import { getAllList } from 'ducks/settings';
import { useLanguage } from 'hooks';
import 'nprogress/nprogress.css';
import './style.scss';
const PrimaryTemplate = ({ route }) => {
    const { i18n } = useLanguage();
    const settings = useSelector(getAllList);
    return (React.createElement("div", { className: "primary-template" },
        React.createElement(Helmet, null,
            React.createElement("title", null,
                "GoldenCheats - ",
                i18n.t('HOME_DEFAULT_PAGE_TITLE')),
            React.createElement("meta", { name: "description", content: i18n.t('HOME_DESCRIPTION').replace(/\n/, '') })),
        React.createElement(Header, { settings: settings }),
        React.createElement("div", { className: "primary-template__content" }, (route === null || route === void 0 ? void 0 : route.routes) && (React.createElement(Switch, null, renderRoutes(route.routes)))),
        React.createElement(Footer, { settings: settings })));
};
export default PrimaryTemplate;
