import React from 'react';
import { useLanguage, useTabs, useSSREffect } from 'hooks';
import { Button } from 'ui/atoms';
export const Tabs = ({ isHasScreenshots, isHasVideos }) => {
    const { i18n } = useLanguage();
    const { updateActiveTab, tab } = useTabs();
    useSSREffect(() => {
        if (!isHasVideos && tab === 1) {
            updateActiveTab(0);
        }
    }, [isHasVideos, tab]);
    return (React.createElement("div", { className: "media-tabs__button-group" },
        isHasScreenshots && (React.createElement(Button, { onClick: () => updateActiveTab(0), active: tab === 0, style: "link" }, i18n.t('CHEAT_TAB_SCREENSHOTS'))),
        isHasVideos && (React.createElement(Button, { onClick: () => updateActiveTab(1), active: tab === 1, style: "link" }, i18n.t('CHEAT_TAB_VIDEOS')))));
};
