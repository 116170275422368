var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useLanguage } from 'hooks';
const NavLink = (_a) => {
    var { to, children } = _a, rest = __rest(_a, ["to", "children"]);
    const { language } = useLanguage();
    return (React.createElement(RouterNavLink, Object.assign({ to: `/${language}${to}`, activeClassName: "active" }, rest), children));
};
export default NavLink;
