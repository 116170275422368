import React from 'react';
import { Cheat } from 'ui/molecules';
import { useLanguage } from 'hooks';
const HomeCheats = ({ cheats }) => {
    const { tData } = useLanguage();
    return (React.createElement("div", { className: "game-template__list" }, cheats.map(cheat => {
        var _a, _b;
        return (React.createElement(Cheat, { key: cheat._id.toString(), gameSlug: (_a = cheat.game) === null || _a === void 0 ? void 0 : _a.slug, gameName: (_b = cheat.game) === null || _b === void 0 ? void 0 : _b.title, cheatSlug: cheat.slug, title: cheat.name, preview: cheat.preview, features: tData(cheat.features), status: cheat.status }));
    })));
};
export default HomeCheats;
