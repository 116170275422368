import { isBrowser } from './selector';
export const noop = () => { };
export const computeIsMobile = () => isBrowser
    ? window.matchMedia('(max-width: 767px)').matches
    : false;
export const computeIsTablet = () => isBrowser
    ? window.innerWidth >= 768 && window.innerWidth < 1024
    : false;
export const computeIsDesktop = () => isBrowser
    ? window.innerWidth >= 1024
    : false;
const decCache = [], decCases = [2, 0, 1, 1, 1, 2];
export const decOfNum = (number, titles) => {
    if (!decCache[number]) {
        decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
    }
    return titles[decCache[number]];
};
