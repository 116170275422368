import React, { createContext, useState, useCallback, } from 'react';
import { noop, computeIsMobile, computeIsTablet, computeIsDesktop, } from 'utils/functions';
import { useListener } from 'hooks';
import { getWindow } from 'utils/selector';
export const AdaptiveContext = createContext({
    isMobile: true,
    isTablet: false,
    isDesktop: false,
    addResizeListener: noop,
    removeResizeListener: noop,
});
export const AdaptiveConsumer = AdaptiveContext.Consumer;
const listeners = [];
const AdaptiveProvider = ({ ssrIsMobile, ssrIsTablet, ssrIsDesktop, children, }) => {
    const [isMobile, setIsMobile] = useState(ssrIsMobile || computeIsMobile());
    const [isTablet, setIsTablet] = useState(ssrIsTablet || computeIsTablet());
    const [isDesktop, setIsDesktop] = useState(ssrIsDesktop || computeIsDesktop());
    const setValues = useCallback(event => {
        setIsMobile(computeIsMobile());
        setIsTablet(computeIsTablet());
        setIsDesktop(computeIsDesktop());
        listeners.forEach(listener => typeof listener === 'function' && listener(event));
    }, []);
    const addResizeListener = useCallback(listener => {
        listeners.push(listener);
    }, []);
    const removeResizeListener = useCallback(listener => {
        const index = listeners.indexOf(listener);
        index !== -1 && listeners.splice(index, 1);
    }, []);
    useListener({
        target: { current: getWindow() },
        event: 'resize',
        listener: setValues,
    });
    return (React.createElement(AdaptiveContext.Provider, { value: {
            isMobile,
            isTablet,
            isDesktop,
            addResizeListener,
            removeResizeListener,
        } }, children));
};
AdaptiveProvider.defaultProps = {
    ssrIsMobile: undefined,
    ssrIsTablet: undefined,
    ssrIsDesktop: undefined,
};
export default AdaptiveProvider;
