import React from 'react';
import './style.scss';
const Loader = ({ width, height }) => (React.createElement("div", { className: "loader" },
    React.createElement("svg", { width: width, height: height, viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { cx: "50", cy: "50", r: "45" }))));
Loader.defaultProps = {
    height: '65px',
    width: '65px',
};
export default Loader;
