import { action } from 'typesafe-actions';
import { FETCH_DATA_SUCCESS, FETCH_STATUSES_SUCCESS, FETCH_FAILED, } from './constants';
export const fetchFailed = (text) => action(FETCH_FAILED, {
    text,
});
export const fetchDataSuccess = (game) => action(FETCH_DATA_SUCCESS, {
    game,
});
export const fetchStatusesSuccess = (statuses) => action(FETCH_STATUSES_SUCCESS, {
    statuses,
});
