import { fetchSettings } from 'ducks/settings';
import { fetchCheat } from 'ducks/cheat';
import { fetchLastUpdatedGames } from 'ducks/home';
const loadData = ({ store, match }) => {
    const { game, cheat } = match.params;
    return Promise.all([
        store.dispatch(fetchSettings()),
        store.dispatch(fetchCheat(cheat, game)),
        store.dispatch(fetchLastUpdatedGames()),
    ]);
};
export default loadData;
