import React from 'react';
import { Game } from 'ui/molecules';
import { useLanguage } from 'hooks';
import './style.scss';
const HomeGames = ({ settings, games, }) => {
    const { tData } = useLanguage();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "home-games" }, games.map(game => (React.createElement(Game, { key: game._id.toString(), game: game })))),
        (settings && !!tData(settings === null || settings === void 0 ? void 0 : settings.homeText)) && (React.createElement("div", { className: "game-template__description game-template__seo" },
            React.createElement("p", null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: tData(settings.homeText) } }))))));
};
export default HomeGames;
