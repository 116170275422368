import React, { useCallback } from 'react';
import { useField, useFormState } from 'react-final-form';
import { formatPrice } from 'utils/selector';
import { useLanguage } from 'hooks';
import './style.scss';
const RadioField = ({ name, text, value, price }) => {
    const { i18n } = useLanguage();
    const { values } = useFormState();
    const { input } = useField(name);
    const handleClick = useCallback(() => input.onChange(value), [value, input.onChange]);
    return (React.createElement("div", { className: "radio-field", "data-active": values[name] === value, onClick: handleClick },
        React.createElement("div", { className: "radio-field__period" }, text),
        price && (React.createElement("div", { className: "radio-field__price" }, formatPrice(price, i18n)))));
};
export default RadioField;
