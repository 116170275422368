import { useMount } from 'hooks';
import React, { useState } from 'react';
const DefaultOnSSR = () => React.createElement("span", { "data-name": "no-ssr" });
const NoSSR = ({ onSSR: OnSSR, children, }) => {
    const [canRender, setCanRender] = useState(false);
    useMount(() => setCanRender(true));
    return (React.createElement(React.Fragment, null, canRender
        ? children
        : OnSSR));
};
NoSSR.defaultProps = {
    onSSR: React.createElement(DefaultOnSSR, null),
};
export default NoSSR;
