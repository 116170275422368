import React from 'react';
import { useSelector } from 'react-redux';
import { getCount, getLastUpdatedGames } from 'ducks/home';
import { LastUpdatedSlider } from 'ui/organisms';
import { BackgroundWallpaper, preventFlashWallpaperState, usePreventFlashWallpaper } from 'ui/atoms';
import { useLanguage } from 'hooks';
import NavLink from 'i18n/NavLink';
import './style.scss';
const state = preventFlashWallpaperState();
const HomeTemplate = ({ children }) => {
    const { i18n } = useLanguage();
    const count = useSelector(getCount);
    const lastUpdated = useSelector(getLastUpdatedGames);
    usePreventFlashWallpaper(state);
    return (React.createElement(React.Fragment, null,
        React.createElement(BackgroundWallpaper, { mounted: state.mounted }),
        React.createElement("div", { className: "home-template" },
            React.createElement("h1", { className: "home-template__title" },
                "GOLDEN",
                React.createElement("span", null, "CHEATS"),
                " - ",
                i18n.t('HOME_DESCRIPTION')),
            React.createElement("div", { className: "home-template__tabs" },
                React.createElement(NavLink, { to: "/", exact: true, className: "home-template__tabs-link" }, i18n.t('HOME_TAB_GAMES').replace('#count#', count
                    ? `${count.games}`
                    : '~')),
                React.createElement(NavLink, { to: "/cheats/", className: "home-template__tabs-link" }, i18n.t('HOME_TAB_CHEATS').replace('#count#', count
                    ? `${count.cheats}`
                    : '~'))),
            children,
            React.createElement(LastUpdatedSlider, { lastUpdated: lastUpdated }))));
};
export default HomeTemplate;
