import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { GameTemplate, NotFound } from 'ui/templates';
import { useLanguage } from 'hooks';
import { getFailed, getData, } from 'ducks/game';
const Game = () => {
    const { tData } = useLanguage();
    const game = useSelector(getData);
    const error = useSelector(getFailed);
    if (error)
        return React.createElement(NotFound, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, tData(game === null || game === void 0 ? void 0 : game.page.title)),
            React.createElement("meta", { name: "description", content: tData(game === null || game === void 0 ? void 0 : game.page.description) })),
        React.createElement(GameTemplate, { game: game })));
};
export default Game;
