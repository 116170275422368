export const defaultLng = 'ru';
export const languageList = ['ru', 'en'];
export const createI18n = (lang, dictionary) => ({
    language: lang,
    dictionary,
    t(key) {
        const string = this.dictionary[key];
        if (typeof string === 'undefined') {
            return key;
        }
        return string;
    },
});
