import { matchRoutes } from 'libs/router-config';
import React, { useMemo, useRef, } from 'react';
import { useStore } from 'react-redux';
import NProgress from 'nprogress';
import { Switch, useLocation } from 'react-router-dom';
import { useForceUpdate, useSSREffect, usePrevious, } from 'hooks';
import { scrollTop } from './utils';
export const removeLangPrefix = (path) => path
    .replace('/ru/', '/')
    .replace('/en/', '/');
export const PendingSwitch = ({ routes, children }) => {
    const store = useStore();
    const location = useLocation();
    const [, oldLocationRef] = usePrevious(location);
    const forceUpdate = useForceUpdate();
    const normalizedPath = useMemo(() => {
        const pathWithoutLangPrefix = removeLangPrefix(location.pathname);
        return pathWithoutLangPrefix === ''
            ? '/'
            : pathWithoutLangPrefix;
    }, [location.pathname]);
    const loadedPathRef = useRef(normalizedPath);
    useSSREffect(() => {
        var _a;
        if ((loadedPathRef.current !== normalizedPath
            || ((_a = oldLocationRef.current) === null || _a === void 0 ? void 0 : _a.search) !== location.search)
            && routes) {
            const currentRoute = matchRoutes(routes, location.pathname);
            const currentPath = removeLangPrefix(location.pathname);
            const prevPath = removeLangPrefix(loadedPathRef.current);
            const isHasLoadData = currentRoute.some(r => typeof r.route.loadData === 'function');
            const scroll = () => {
                const preventScroll = ['/', '/cheats/'];
                if (!preventScroll.includes(currentPath) || !preventScroll.includes(prevPath)) {
                    scrollTop();
                }
            };
            if (isHasLoadData) {
                loadedPathRef.current = normalizedPath;
                if (window.__INITIAL_STATE__.store === undefined) {
                    NProgress.start();
                    const loadAll = Promise.all(currentRoute.map(r => typeof r.route.loadData === 'function'
                        ? r.route.loadData({
                            store,
                            req: undefined,
                            res: undefined,
                            match: r.match,
                        })
                        : Promise.resolve(null)));
                    loadAll
                        .then(() => {
                        scroll();
                        forceUpdate();
                        NProgress.done();
                    })
                        .catch(() => {
                        scroll();
                        forceUpdate();
                        NProgress.done();
                    });
                }
            }
            else {
                console.log(NaN);
                loadedPathRef.current = normalizedPath;
                forceUpdate();
                scroll();
            }
        }
    }, [normalizedPath, location.search]);
    return (React.createElement(Switch, { location: oldLocationRef.current || location }, children));
};
