import React from 'react';
import { useLanguage, useMobile } from 'hooks';
import { DesktopSlider, MobileSlider } from './components';
import './style.scss';
const LastUpdatedSlider = ({ lastUpdated, }) => {
    const { i18n } = useLanguage();
    const { isMobile, isTablet } = useMobile();
    return (React.createElement("div", { className: "last-updated-slider" },
        React.createElement("div", { className: "last-updated-slider__title" }, i18n.t('LAST_UPDATED_TITLE')),
        (!isMobile && !isTablet)
            ? React.createElement(DesktopSlider, { items: lastUpdated })
            : React.createElement(MobileSlider, { items: lastUpdated })));
};
export default LastUpdatedSlider;
