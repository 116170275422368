import React from 'react';
import { useLanguage, useMobile } from 'hooks';
import { formatPrice } from 'utils/selector';
import { decOfNum } from 'utils/functions';
import { Image } from 'ui/atoms';
import Link from 'i18n/Link';
import './style.scss';
const Game = ({ game, }) => {
    const { i18n, tData } = useLanguage();
    const { isMobile } = useMobile();
    return (React.createElement(Link, { to: `/game/${game.slug}/`, className: "game__item" },
        React.createElement("div", { className: "game__preview" },
            React.createElement(Image, { jpeg: game.preview.jpeg, webp: game.preview.webp, alt: game.title })),
        React.createElement("div", { className: "game__title" }, game.title),
        React.createElement("div", { className: "game__price" }, formatPrice(tData(game.minPrice), i18n)),
        React.createElement("div", { className: "game__overlay" },
            React.createElement("span", { className: "game__goods" },
                game.cheatsCount,
                " ",
                decOfNum(game.cheatsCount, [
                    i18n.t('GAME_GOODS_SINGLE'),
                    i18n.t('GAME_GOODS_FEW'),
                    i18n.t('GAME_GOODS_MANY'),
                ])),
            React.createElement("div", { className: "game__btn" }, i18n.t(isMobile
                ? 'GAME_CATALOG'
                : 'GAME_CHEATS_LIST')))));
};
export default Game;
