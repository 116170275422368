var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Icon } from 'ui/atoms';
import Link from 'i18n/Link';
const Logo = (_a) => {
    var { className } = _a, rest = __rest(_a, ["className"]);
    return (React.createElement(Link, { to: "/", className: `logo ${className}` },
        React.createElement(Icon, Object.assign({ name: "logo", width: "120", height: "52" }, rest))));
};
export default Logo;
